console.log('Vite ⚡️ Rails')
import "admin-lte"
import "bootstrap"
import { createApp } from "vue/dist/vue.esm-bundler"
import "jquery"
import "@rails/ujs"
import Rails from "@rails/ujs"
const backend_data=window.backend_data
import JsonEditorVue from 'json-editor-vue'
import "flag-icons"

import { boton_desarrollo_app } from "./apps/boton_desarrollo"
import { script_editor_app } from "./apps/script_editor"
import { dashboard_app } from "./apps/dashboard"
import { templates_app } from "./apps/templates"


Rails.start();

const botondesarrollo_tag = document.querySelector("#botondesarrollo")
const script_editor_tag = document.querySelector("#script_editor")
const dashboard_tag = document.querySelector("#dashboard")
const templates_tag = document.querySelector("#templates")


if (dashboard_tag !== null) {const app=createApp(dashboard_app).mount(dashboard_tag)}
if (botondesarrollo_tag !== null) {const app=createApp(boton_desarrollo_app).mount(botondesarrollo_tag)}
if (script_editor_tag !== null) {const app=createApp(script_editor_app).use(JsonEditorVue).mount(script_editor_tag)}
if (templates_tag !== null) {const app=createApp(templates_app).mount(templates_tag)}
